<template>
    <div class="basic_data_edit">
        <div class="data_content scrollbar">
            <!-- 按钮 -->
            <div class="botton_box">
                <span class="menu_title">现行规划执行情况记录</span>
                <div class="button_list">
                    <sn-button
                        :snButton="snButton"
                        :class="{ showButton: !saveButton }"
                        @handleChange="handleChange"
                    ></sn-button>
                </div>
            </div>
            <!-- 表单 -->
            <el-form
                ref="formdata"
                :inline="true"
                :model="formdata.dataDic"
                :rules="rules"
                class="demo-form-inline form_row"
                size="small"
                label-width="200px"
            >
                <el-form-item
                    prop="JQGHXM"
                    label="近期规划项目"
                    class="from-item-block"
                >
                    <el-input
                        v-model="formdata.dataDic.JQGHXM"
                        :disabled="!isEdit"
                        :maxlength="50"
                    ></el-input>
                </el-form-item>
                <el-form-item
                    prop="BHGLGHID"
                    label="保护管理规划"
                    class="from-item-block"
                >
                    <el-select
                        v-model="formdata.dataDic.BHGLGHID"
                        :disabled="!isEdit || $route.query.JCDXID"
                    >
                        <el-option
                            v-for="(item, index) in projectcol"
                            :key="`${index}`"
                            :value="item.ID"
                            :label="item.MC"
                        >
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item
                    prop="GHXMLB"
                    label="规划项目类别"
                    class="from-item-block"
                >
                    <el-cascader
                        v-model="formdata.dataDic.GHXMLB"
                        style="width: 100%"
                        :options="ghxmlxTree"
                        :props="cascprops"
                        change-on-select
                        :disabled="!isEdit"
                        @change="handleGHXMLBchanged"
                    ></el-cascader>
                </el-form-item>
                <el-form-item
                    prop="SSZT"
                    label="实施状态"
                    class="from-item-block"
                >
                    <el-select
                        v-model="formdata.dataDic.SSZT"
                        class="vali"
                        :disabled="!isEdit"
                        @change="sszt_changeHandler"
                    >
                        <el-option
                            v-for="(item, index) in ssztData"
                            :key="`${index}`"
                            :value="item.CODE"
                            :label="item.LABEL"
                        ></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item
                    v-if="showWssyy"
                    prop="WSSYY"
                    label="未实施原因"
                    class="from-item-block"
                >
                    <el-input
                        v-model="formdata.dataDic.WSSYY"
                        class="vali"
                        :autosize="{ minRows: 2, maxRows: 6 }"
                        :disabled="!isEdit"
                        resize="none"
                        type="textarea"
                        :maxlength="500"
                    ></el-input>
                </el-form-item>
                <el-form-item
                    prop="ZXQKZHPJ"
                    label="执行情况综合评价"
                    class="from-item-block"
                >
                    <el-select
                        v-model="formdata.dataDic.ZXQKZHPJ"
                        class="vali"
                        :disabled="!isEdit"
                    >
                        <el-option
                            v-for="(item, index) in zxqkzhpjData"
                            :key="`${index}`"
                            :value="item.CODE"
                            :label="item.LABEL"
                        ></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item prop="BZ" label="说明" class="from-item-block">
                    <el-input
                        v-model="formdata.dataDic.BZ"
                        resize="none"
                        :autosize="{ minRows: 2, maxRows: 6 }"
                        type="textarea"
                        :disabled="!isEdit"
                        :maxlength="500"
                    ></el-input>
                </el-form-item>
            </el-form>
        </div>
    </div>
</template>
<script>
import { mapActions } from "vuex";
import infoMixin from "../0_com_js/info_mixin.js";
export default {
    name: "xxghzxqkjl",
    mixins: [infoMixin],
    data() {
        return {
            isEdit: true,
            saveButton: true,
            formdata: {
                itemCode: "1602",
                dataDic: {
                    glycbtid: this.heritageId,
                    JQGHXM: "",
                    BHGLGHID: "",
                    GHXMLB: "",
                    SSZT: "",
                    WSSYY: "",
                    ZXQKZHPJ: "",
                    BZ: "",
                    JCDXID: null,
                },
            },
            rules: {
                JQGHXM: [
                    {
                        required: true,
                        message: "请填写近期规划项目",
                        trigger: "blur",
                    },
                ],
                BHGLGHID: [
                    {
                        required: true,
                        message: "请选择保护管理规划",
                        trigger: "change",
                    },
                ],
                GHXMLB: [
                    {
                        required: true,
                        message: "请选择规划项目类别",
                        trigger: "change",
                    },
                ],
                SSZT: [
                    {
                        required: true,
                        message: "请选择实施状态",
                        trigger: "change",
                    },
                ],
            },
            ssztData: [],
            zxqkzhpjData: [],
            ghxmlxTree: [],
            projectcol: [],
            showWssyy: false, // 是否显示未实施原因
            selectedOptions: [],
            cascprops: {
                value: "ID",
                label: "LBMC",
                children: "CHILDREN",
            },
        };
    },
    computed: {},
    mounted() {
        //只从自己的页面调用枚举
        this.GetEnum();
        this.GetGhxmlbList();
        this.getDataList();
        this.formdata.dataDic.BHGLGHID = this.$route.query.JCDXID;
    },
    methods: {
        ...mapActions([
            "getAllEnumInfobytableID",
            "GetGhxmlbListTree",
            "getBasicList",
        ]),
        async GetEnum() {
            let res = await this.getAllEnumInfobytableID({
                bid: "1602",
            });
            let enumList = res || [];
            enumList.forEach((v) => {
                if (v.COLUMNID === "SSZT") {
                    // 实施状态
                    this.ssztData = v.dataItems;
                } else if (v.COLUMNID === "ZXQKZHPJ") {
                    // 执行情况综合评价
                    this.zxqkzhpjData = v.dataItems;
                }
            });
        },
        // 规划项目类别
        async GetGhxmlbList() {
            let res = await this.GetGhxmlbListTree(null);
            let enumList = res || [];
            this.ghxmlxTree = res.ResultValue || [];
        },
        sszt_changeHandler(v) {
            this.showWssyy = v === "2" ? true : false;
        },
        handleGHXMLBchanged(value) {
            console.log(value, "value");
            if (!value) return;
            if (value.length == 0) return;
            this.formdata.dataDic.GHXMLB = value[value.length - 1];
        },
        // 获得 保护管理规划记录 列表
        async getDataList() {
            const { ResultValue: res } = await this.getBasicList({
                userid: localStorage.userId,
                ycdid: this.heritageId,
                itemId: 19,
                authority: false,
                keyWord: "",
                strwhere: `[]`,
                pageIndex: 1,
                pageSize: 999,
                sort: "",
                order: "",
            });
            this.projectcol = res.gridData ? res.gridData : [];
        },
        GetDataNext() {
            this.sszt_changeHandler(this.formdata.dataDic.SSZT);
        },
    },
};
</script>
<style scoped></style>
